import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
          
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/legal/contact-us/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/legal/contact-us/">UK</a>
                      </li>
                      <li>
                        <a href="/es/legal/contactos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/legal/contactos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/legal/contact-us/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/legal/contact-us/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="index-hero">
              <div id="bgImage">
                <div className="areaInner"></div>
              </div>
            </section>
            <section id="content">
            <div className="innerContent">
                <h2>CONTACT US</h2>
                <p>
                  <strong>Johnson &amp; Johnson Limited</strong>, <br />
                  50 - 100 Holmers Farm Way
                  <br />
                  High Wycombe
                  <br />
                  HP12 4EG
                  <br />
                  United Kingdom
                  <br />
                  Company No. 02175750
                  <br />
                  By webform: <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pJ#etd=::00X,v9u?z00Y00iJ00L00c?Z9W00Y00aFVV9,00L00&Z9TvZTV00Y00V900L00vZn9vOcV?00Y00iannpB5ppooBnn)100ww$#etd=::00X,v9u?z00Y00iJ00L00c?Z9W00Y00aFVV9,00L00&Z9TvZTV00Y00V900L00vZn9vOcV?00Y00iannpB5ppooBnn)100ww$#etd=::00c?Z9W00Y00MVvu?,TV9Z00ww$">Click here</a>
                </p>
                <p>
                Please use the webform to ask us a question or phone us between opening hours.
                </p>
                <p>
                  Unfortunately, we are unable to provide advice on personal
                  medical matters.
                  <br />
                  Please consult your GP, pharmacist or NHS Direct on 0845 46
                  47.
                </p>
                <p>
                  Should you have any concerns or cause for dissatisfaction with
                  any of our products, we want to know about it. We can best
                  assist you by speaking with you directly.
                </p>
                <p>
                  Please contact us on +44 845 601 5792 between 9:30am - 5:00pm
                  (GMT), Monday-Friday.
                </p>
                <p> </p>
                <h3>DK</h3>
                <p>KONTAKT OS</p>
                <p>
                  Hvis du har spørgsmål, er du velkommen til at kontakte os.
                </p>
                <p>
                  Tel: 00 800 855 000 00, Mandag – Fredag kl. 9.00 -16.00 (med
                  undtagelse af helligdage)
                </p>
                <p>
                By webform: <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pIAAQ">Click here</a>
                </p>
                <h3>FI</h3>
                <p>OTA YHTEYTTÄ</p>
                <p>
                  Mikäli olet tyytymätön tuotteeseen tai sinulla on kysyttävää,
                  haluamme kuulla siitä.
                </p>
                <p>Puhelin: 00 800 855 000 00, arkisin (ma-pe) klo 10-17.</p>
                <p>
                Verkkolomakkeella: <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pAAAQ">Klikkaa tästä</a>
                </p>
                <h3>SE</h3>
                <p>Kontakta oss</p>
                <p>
                  Vi vill gärna hjälpa dig, så tveka inte att kontakta oss! Dina
                  frågor och synpunkter betyder mycket för oss.
                </p>
                <p>Telefon: 020-42 11 11 (helgfria vardagar kl. 9-16)</p>
                <p>
                webbformulär: <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pOAAQ">Klicka här</a>
                </p>
                <h3>NO</h3>
                <p>KONTAKT OSS</p>
                <p>
                  Vi vil gjerne vite det dersom du har bekymringer eller har
                  andre grunner til misnøye med noen av våre produkter, samt
                  evt. andre spørsmål eller kommentarer.
                </p>
                <p>
                  Telefon: 00 800 855 000 00, mellom 9:00-16:00, mandag-fredag
                  (med unntak av offentlige høytidsdager)
                </p>
                <p>
                Webskjema: <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p5AAA">Klikk her</a>
                </p>
                <h3>GERMANY</h3>
                <p>
                  Kontaktieren Sie uns{" "}
                  <a target="_blank" href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8#etd=::00c?Z9W00Y00r_800ww$">
                    hier
                  </a>
                </p>
                <h3>AUSTRIA</h3>
                <p>
                  Kontaktieren Sie uns{" "}
                  <a target="_blank" href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p8#etd=::00c?Z9W00Y00r_800ww$">
                    hier
                  </a>
                </p>
                <h3>SWITZERLAND</h3>
                <p>
                  Kontaktieren Sie uns{" "}
                  <a target="_blank" href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pF">
                    hier
                  </a>
                </p>
                <p>
                  Contactez-nous{" "}
                  <a target="_blank" href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645pN">
                    ici
                  </a>
                </p>
                <h3>FRANCE</h3>
                <p>
                  Pour toute demande d’information ou réclamation, vous pouvez utiliser notre{" "}
                  <a target="_blank" href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a071i000018lHVRAA2#etd=::00c?Z9W00Y00'?Z9WGvc00ww$">
                    formulaire de contact 
                  </a> ou via le numéro de notre service d´information au : 00 800 555 220 00 (numéro vert international gratuit un poste fixe).
                </p>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $("#bgImage img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$("#bgImage img").remove();\n});\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
